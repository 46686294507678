import * as React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import PageWrapper from '../components/pageWrapper/PageWrapper';
import localize from '../hoc/Localize';
import './policy.scss';

const PrivacyPolicyPage = props => {
  //const { sanityLegal } = props.data;
  let sanityLegal = {
    "title": "Privacy Policy",
    "_rawTerms": [
      {
        "_key": "637127acd067",
        "_type": "block",
        "children": [
          {
            "_key": "637127acd0670",
            "_type": "span",
            "marks": [],
            "text": "This privacy statement (“Privacy Statement”) applies to the treatment of personally identifiable information submitted by, or otherwise obtained from, you in connection with the associated application (“Application”). The Application is provided by Fitplan Technologies Inc. (and may be provided by Fitplan Technologies Inc. on behalf of a Fitplan Technologies Inc. licensor or partner (“Application Partner”). By using or otherwise accessing the Application, you acknowledge that you accept the practices and policies outlined in this Privacy Statement."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "ef8cd0cb0c35",
        "_type": "block",
        "children": [
          {
            "_key": "ef8cd0cb0c350",
            "_type": "span",
            "marks": [],
            "text": "WHAT PERSONAL INFORMATION DOES Fitplan Technologies Inc. COLLECT?"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "49df0efbe296",
        "_type": "block",
        "children": [
          {
            "_key": "49df0efbe2960",
            "_type": "span",
            "marks": [],
            "text": "We collect the following types of information from our users:"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "84534fd1661a",
        "_type": "block",
        "children": [
          {
            "_key": "84534fd1661a0",
            "_type": "span",
            "marks": [],
            "text": "Personal Information You Provide to Us:"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "65f5256edb86",
        "_type": "block",
        "children": [
          {
            "_key": "65f5256edb860",
            "_type": "span",
            "marks": [],
            "text": "We may receive and store any information you submit to the Application (or otherwise authorize us to obtain – such as, from (for example) your Facebook account). The types of personal information collected may include your full name, email address, gender, IP address, browser information, username, demographic information, and any other information necessary for us to provide the Application services."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "b944a22c6859",
        "_type": "block",
        "children": [
          {
            "_key": "b944a22c68590",
            "_type": "span",
            "marks": [],
            "text": "Personal Information Collected Automatically:"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "dfd86b66e9fe",
        "_type": "block",
        "children": [
          {
            "_key": "dfd86b66e9fe0",
            "_type": "span",
            "marks": [],
            "text": "We receive and store certain types of usage related information whenever you interact with Application. For example, Fitplan Technologies Inc. may automatically receive and record information regarding your computer’s IP address, browser information, Facebook user ID, Facebook Page fan status, and URLs accessed. Such information may be shared in aggregate (non-personally identifiable) form with our partners."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "801fee1185fc",
        "_type": "block",
        "children": [
          {
            "_key": "801fee1185fc0",
            "_type": "span",
            "marks": [],
            "text": "HOW DOES Fitplan Technologies Inc. USE THE INFORMATION IT COLLECTS?"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "b1336dde9356",
        "_type": "block",
        "children": [
          {
            "_key": "b1336dde93560",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc. uses the information described in this Privacy Statement (i) internally, to analyze, develop and improve its products and services, and (ii) as set forth below in the “Will Fitplan Technologies Inc. Share any of the personal information it Collects” section below."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "af22ada7d6ee",
        "_type": "block",
        "children": [
          {
            "_key": "af22ada7d6ee0",
            "_type": "span",
            "marks": [],
            "text": "APPLICATION PARTNER TREATMENT OF PERSONAL INFORMATION"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "87a20fbdb4f9",
        "_type": "block",
        "children": [
          {
            "_key": "87a20fbdb4f90",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc. may provide personal information to the applicable Application Partner. The Application Partner’s use of your personal information is subject to the Application Partner’s separate privacy policy – and not this Privacy Statement. The Application Partner’s privacy policy is linked to from within the Partner’s Facebook application or volunteered by the user."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "6be8ce2c5808",
        "_type": "block",
        "children": [
          {
            "_key": "6be8ce2c58080",
            "_type": "span",
            "marks": [],
            "text": "WILL Fitplan Technologies Inc. SHARE ANY OF THE PERSONAL INFORMATION IT RECEIVES?"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "6894c7bb211a",
        "_type": "block",
        "children": [
          {
            "_key": "6894c7bb211a0",
            "_type": "span",
            "marks": [],
            "text": "Personal information about our users is an integral part of our business. We neither rent nor sell your personal information to anyone (with the exception of sharing your information with an applicable Application Partner – see the “Application Partner Treatment” section above). We share your personal information only as described below."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "a3fac0e98da3",
        "_type": "block",
        "children": [
          {
            "_key": "a3fac0e98da30",
            "_type": "span",
            "marks": [],
            "text": "Application Partners: We will share your personal information with an applicable Application Partner (see the “Application Partner Treatment” section above)."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "5f29ab3f80d6",
        "_type": "block",
        "children": [
          {
            "_key": "5f29ab3f80d60",
            "_type": "span",
            "marks": [],
            "text": "Agents: We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you. Unless we tell you differently, Fitplan Technologies Inc.’s agents do not have any right to use personal information we share with them beyond what is necessary to assist us. You hereby consent to our sharing of personal information for the above purposes. Business Transfers: In some cases, we may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that are transferred. Moreover, if Fitplan Technologies Inc., or substantially all of its assets were acquired, or in the unlikely event that Fitplan Technologies Inc. goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Fitplan Technologies Inc. may continue to use your personal information as set forth in this policy."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "b1dba0244a9e",
        "_type": "block",
        "children": [
          {
            "_key": "b1dba0244a9e0",
            "_type": "span",
            "marks": [],
            "text": "Protection of Fitplan Technologies Inc. and Others: We may release personal information when we believe in good faith that release is necessary to comply with the law; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Fitplan Technologies Inc., our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "852607b41b8b",
        "_type": "block",
        "children": [
          {
            "_key": "852607b41b8b0",
            "_type": "span",
            "marks": [],
            "text": "With Your Consent: Except as set forth above, you will be notified when your personal information may be shared with third parties, and will be able to prevent the sharing of this information."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "6be8ce2c58088",
        "_type": "block",
        "children": [
          {
            "_key": "6be8ce2c580800",
            "_type": "span",
            "marks": [],
            "text": "HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA THAT WE COLLECT FROM YOU?"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "6be8ce2c58089",
        "_type": "block",
        "children": [
          {
            "_key": "6be8ce2c580801",
            "_type": "span",
            "marks": [],
            "text": "Based on the applicable laws of your country, you may have the right to request access to the personal information that we collect from you, change that information, or delete it in some circumstances.  To request to review, update or delete your personal information, please submit a request to [support@fitplanapp.com]"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "ea7d7e65ca4f",
        "_type": "block",
        "children": [
          {
            "_key": "ea7d7e65ca4f0",
            "_type": "span",
            "marks": [],
            "text": "CONDITIONS OF USE"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "f5fea596031f",
        "_type": "block",
        "children": [
          {
            "_key": "f5fea596031f0",
            "_type": "span",
            "marks": [],
            "text": "If you decide to use or otherwise access the Application, your use/access and any possible dispute over privacy is subject to this Privacy Statement and our Terms of Use, including limitations on damages, arbitration of disputes, and application of California state law."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "bd94a7fb8079",
        "_type": "block",
        "children": [
          {
            "_key": "bd94a7fb80790",
            "_type": "span",
            "marks": [],
            "text": "THIRD PARTY APPLICATIONS/WEBSITES"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "33776d1c430e",
        "_type": "block",
        "children": [
          {
            "_key": "33776d1c430e0",
            "_type": "span",
            "marks": [],
            "text": "The Application may permit you to link to other applications or websites. Such third party applications/websites are not under Fitplan Technologies Inc.’s control, and such links do not constitute an endorsement by Fitplan Technologies Inc. of those other applications/websites or the services offered through them. The privacy and security practices of such third party application/websites linked to the Application are not covered by this Privacy Statement, and Fitplan Technologies Inc. is not responsible for the privacy or security practices or the content of such websites."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "c4c6868a5bcf",
        "_type": "block",
        "children": [
          {
            "_key": "c4c6868a5bcf0",
            "_type": "span",
            "marks": [],
            "text": "WHAT PERSONAL INFORMATION CAN I ACCESS?"
          }
        ],
        "markDefs": [],
        "style": "h2"
      },
      {
        "_key": "583f4881a47e",
        "_type": "block",
        "children": [
          {
            "_key": "583f4881a47e0",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc. allows you to access the following information about you for the purpose of viewing, and in certain situations, updating that information. This list may change in the event the Application changes."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "861a99903c2c",
        "_type": "block",
        "children": [
          {
            "_key": "861a99903c2c0",
            "_type": "span",
            "marks": [],
            "text": "Account and user profile information"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "1af78a8c6086",
        "_type": "block",
        "children": [
          {
            "_key": "1af78a8c60860",
            "_type": "span",
            "marks": [],
            "text": "User e-mail address, if applicable"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "9a9835016fd1",
        "_type": "block",
        "children": [
          {
            "_key": "9a9835016fd10",
            "_type": "span",
            "marks": [],
            "text": "Facebook profile information, if applicable"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "358fb3638553",
        "_type": "block",
        "children": [
          {
            "_key": "358fb36385530",
            "_type": "span",
            "marks": [],
            "text": "User preferences"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "6aad553ba8cc",
        "_type": "block",
        "children": [
          {
            "_key": "6aad553ba8cc0",
            "_type": "span",
            "marks": [],
            "text": "Application specific data"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "452767acd0c2",
        "_type": "block",
        "children": [
          {
            "_key": "452767acd0c20",
            "_type": "span",
            "marks": [],
            "text": "We may share non-personally identifiable information (such as anonymous usage data, referring/exit pages and URLs, platform types, number of clicks, number of users using health kit etc.) with third party analytics providers and other third parties to help them and us understand the usage patterns for certain Services. This Privacy Policy in no way restricts or limits our collection and use of non-personally identifiable information."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "8506a2c8b3c1",
        "_type": "block",
        "children": [
          {
            "_key": "8506a2c8b3c10",
            "_type": "span",
            "marks": [],
            "text": "HEALTHKIT"
          }
        ],
        "markDefs": [],
        "style": "h2"
      },
      {
        "_key": "5b7e6540ec3f",
        "_type": "block",
        "children": [
          {
            "_key": "5b7e6540ec3f0",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc has Apple’s Healthkit integration to track the following PHR (Personal Health Record) Data; duration, steps taken, calories and distance, and exercise type. PHR data is app-based Personal Health Record information. This data is passed through to Healthkit for the purpose of providing real time metric feedback to the user about their workouts, wellbeing and training program.The Healthkit data that we collect is classified as Personal Identifiable Information (PII) and Non- Personal Identifiable Information (NPII) For Clarification purposes:"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "0eccab559385",
        "_type": "block",
        "children": [
          {
            "_key": "0eccab5593850",
            "_type": "span",
            "marks": [],
            "text": "PII is any PHR Data that connects to you as an individual such as names, health conditions, and other identifiers. Any additional PHR data that is PII will fall under this scope"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "d907345dfd56",
        "_type": "block",
        "children": [
          {
            "_key": "d907345dfd560",
            "_type": "span",
            "marks": [],
            "text": "NPII is PHR Data that is 1) grouped so it does not connect to you as an individual and 2) has names and other identifiers removed or altered."
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "5134757a22b8",
        "_type": "block",
        "children": [
          {
            "_key": "5134757a22b80",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc WILL NOT release Healthkit PII or non PII to any person, company or other entity for any reason, including but not limited to:"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "91dc133af504",
        "_type": "block",
        "children": [
          {
            "_key": "91dc133af5040",
            "_type": "span",
            "marks": [],
            "text": "Marketing and Advertising"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "88f61105724f",
        "_type": "block",
        "children": [
          {
            "_key": "88f61105724f0",
            "_type": "span",
            "marks": [],
            "text": "Medical and pharmaceutical research"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "dda5fbf9b4a0",
        "_type": "block",
        "children": [
          {
            "_key": "dda5fbf9b4a00",
            "_type": "span",
            "marks": [],
            "text": "Reporting about our company and or customer activity"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "fa988d432eb0",
        "_type": "block",
        "children": [
          {
            "_key": "fa988d432eb00",
            "_type": "span",
            "marks": [],
            "text": "Your insurer and employer"
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "9c6ae06fc459",
        "_type": "block",
        "children": [
          {
            "_key": "9c6ae06fc4590",
            "_type": "span",
            "marks": [],
            "text": "We may disclose your personal information to third parties should it be required to do so by law or subpoena or if we believe that such action is necessary to (a) conform to the law, comply with legal process served on us or our affiliates, or investigate, prevent, or take action regarding suspected or actual illegal activities; (b) to enforce our Terms of Use, take precautions against liability, to investigate and defend ourselves against any third-party claims or allegations, to assist government enforcement agencies, or to protect the security or integrity of our site; and (c) to exercise or protect the rights, property, or personal safety of Company, or to otherwise act in the best interests of our users or others."
          }
        ],
        "level": 1,
        "listItem": "bullet",
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "7771da901d71",
        "_type": "block",
        "children": [
          {
            "_key": "7771da901d710",
            "_type": "span",
            "marks": [],
            "text": "Your Personal Data is only collected by us from your direct use with the Fitplan app, and if you granted both our app and Apple’s HealthKit permission. If you close or transfer your profile with us, we no longer collect any PHR data"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "0d6f93c30ffc",
        "_type": "block",
        "children": [
          {
            "_key": "0d6f93c30ffc0",
            "_type": "span",
            "marks": [],
            "text": "CAN CHILDREN USE THE APPLICATION?"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "2fb6168a3141",
        "_type": "block",
        "children": [
          {
            "_key": "2fb6168a31410",
            "_type": "span",
            "marks": [],
            "text": "Our site and the services available through Fitplan Technologies Inc. are not intended for children under the age of 13. Fitplan Technologies Inc. does not knowingly or specifically collect information about children under the age of 13 and believes that children of any age should get their parents’ consent before giving out any personal information. We encourage you to participate in your child’s web experience."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "f30203396b5a",
        "_type": "block",
        "children": [
          {
            "_key": "f30203396b5a0",
            "_type": "span",
            "marks": [],
            "text": "CHANGES TO THIS PRIVACY STATEMENT"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "9ce92f2abdc0",
        "_type": "block",
        "children": [
          {
            "_key": "9ce92f2abdc00",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc. may amend this Privacy Statement from time to time. Use of information we collect now is subject to the Privacy Statement in effect at the time such information is used. If we make changes in the way we use personal information, we will notify you by posting an announcement on our Site or sending you an email. Users are bound by any changes to the Privacy Statement when he or she uses or otherwise accesses the Application after such changes have been first posted."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "01c4973ba635",
        "_type": "block",
        "children": [
          {
            "_key": "01c4973ba6350",
            "_type": "span",
            "marks": [],
            "text": "QUESTIONS OR CONCERNS"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "bb6ebe2e24b3",
        "_type": "block",
        "children": [
          {
            "_key": "bb6ebe2e24b30",
            "_type": "span",
            "marks": [],
            "text": "If you have any questions or concerns regarding privacy on our Website, please send us a detailed message at Fitplan Technologies Inc. We will make every effort to resolve your concerns."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "4a0f1f868f39",
        "_type": "block",
        "children": [
          {
            "_key": "4a0f1f868f390",
            "_type": "span",
            "marks": [],
            "text": "Effective Date: January 1, 2017"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "3465e7dfbc6d",
        "_type": "block",
        "children": [
          {
            "_key": "3465e7dfbc6d0",
            "_type": "span",
            "marks": [],
            "text": "Legal Disclaimer"
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "30813fd1fb24",
        "_type": "block",
        "children": [
          {
            "_key": "30813fd1fb240",
            "_type": "span",
            "marks": [],
            "text": "Please review the following User Agreement carefully before using Fitplan app or any intellectual property owned by Fitplan Technologies Inc. You should also read our Privacy Policy."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "3cb789f97094",
        "_type": "block",
        "children": [
          {
            "_key": "3cb789f970940",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc strongly recommends that you consult with your physician before beginning any exercise program."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "7a9821b8f14c",
        "_type": "block",
        "children": [
          {
            "_key": "7a9821b8f14c0",
            "_type": "span",
            "marks": [],
            "text": "You should be in good physical condition and be able to participate in the exercise."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "8861a09fe371",
        "_type": "block",
        "children": [
          {
            "_key": "8861a09fe3710",
            "_type": "span",
            "marks": [],
            "text": "Fitplan Technologies Inc and its trainers are not a licensed medical care provider and represents that it has no expertise in diagnosing, examining, or treating medical conditions of any kind, or in determining the effect of any specific exercise on a medical condition."
          }
        ],
        "markDefs": [],
        "style": "normal"
      },
      {
        "_key": "0f343657ee65",
        "_type": "block",
        "children": [
          {
            "_key": "0f343657ee650",
            "_type": "span",
            "marks": [],
            "text": "You should understand that when participating in any exercise or exercise program, there is the possibility of physical injury. If you engage in this exercise or exercise program, you agree that you do so at your own risk, are voluntarily participating in these activities, assume all risk of injury to yourself, and agree to release and discharge Fitplan Technologies Inc and its trainers from any and all claims or causes of action, known or unknown, arising out of Fitplan Technologies Inc’s negligence."
          }
        ],
        "markDefs": [],
        "style": "normal"
      }
    ]
  };
  if (process.env.GATSBY_L18N_LOCALE === 'es') {
    sanityLegal = {
      "title": "DECLARACIÓN DE PRIVACIDAD DE FITPLAN",
      "_rawTerms": [
        {
          "_key": "91e9fb023297",
          "_type": "block",
          "children": [
            {
              "_key": "91e9fb0232970",
              "_type": "span",
              "marks": [],
              "text": "Esta declaración de privacidad (\"Declaración de Privacidad\") se aplica al tratamiento de la información de identificación personal presentado por, o a su vez obtenido de, usted en relación con la aplicación asociada (\"Aplicación\"). La aplicación es proporcionada por Fitplan Technologies Inc. y puede ser proporcionada por un licenciador en nombre de Fitplan Technologies Inc. o asociado ( \"Asociado de Aplicación\"). Al utilizar o al acceder a la Aplicación, usted reconoce que acepta las prácticas y las políticas descritas en esta Declaración de Privacidad."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "b8b09c3c3404",
          "_type": "block",
          "children": [
            {
              "_key": "b8b09c3c34040",
              "_type": "span",
              "marks": [],
              "text": "¿QUÉ INFORMACIÓN PERSONAL RECOPILA Fitplan Technologies Inc.?"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "6ba9ea611627",
          "_type": "block",
          "children": [
            {
              "_key": "6ba9ea6116270",
              "_type": "span",
              "marks": [],
              "text": "Recopilamos los siguientes tipos de información de nuestros usuarios:"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "34672179ce45",
          "_type": "block",
          "children": [
            {
              "_key": "34672179ce450",
              "_type": "span",
              "marks": [],
              "text": "Información personal que Usted nos proporciona:"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "7e9cfc285461",
          "_type": "block",
          "children": [
            {
              "_key": "7e9cfc2854610",
              "_type": "span",
              "marks": [],
              "text": "Podemos recibir y almacenar cualquier información que usted envíe a la Aplicación (o de otra manera nos autoriza a obtener - como, de (por ejemplo) su cuenta de Facebook). Los tipos de información personal recopilada puede incluir su nombre completo, dirección de correo electrónico, sexo, dirección IP, información del navegador, nombre de usuario, información demográfica y cualquier otra información necesaria para nosotros poder proporcionar los servicios de la Aplicación."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "b65b44571e8b",
          "_type": "block",
          "children": [
            {
              "_key": "b65b44571e8b0",
              "_type": "span",
              "marks": [],
              "text": "Información Personal Recopilada Automáticamente:"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "b1e67177602d",
          "_type": "block",
          "children": [
            {
              "_key": "b1e67177602d0",
              "_type": "span",
              "marks": [],
              "text": "Recibimos y almacenamos ciertos tipos de información relacionada con el uso, cada vez que interactúa con la aplicación. Por ejemplo, Fitplan Technologies Inc. puede recibir automáticamente y registrar la información relativa a la dirección IP de su ordenador, información del navegador, ID de usuario de Facebook, la Página de Facebook del estatus de los fans, y las URLs visitadas. Dicha información puede ser compartida de forma agregada (no identificable personalmente) con nuestros socios."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "9c20f3812dc7",
          "_type": "block",
          "children": [
            {
              "_key": "9c20f3812dc70",
              "_type": "span",
              "marks": [],
              "text": "¿CÓMO USA LA INFORMACIÓN QUE RECOPILA Fitplan Technologies Inc.?"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "2ce9af657535",
          "_type": "block",
          "children": [
            {
              "_key": "2ce9af6575350",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. utiliza la información descrita en esta Declaración de Privacidad (i) internamente, para analizar, desarrollar y mejorar sus productos y servicios, y (ii) como se indica a continuación en la siguiente sección \"Fitplan Technologies Inc. compartirá cualquier información personal que recopila\"."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "2a75f0e4f3fb",
          "_type": "block",
          "children": [
            {
              "_key": "2a75f0e4f3fb0",
              "_type": "span",
              "marks": [],
              "text": "ACUERDO DE LOS SOCIOS SOBRE LA APLICACIÓN DE DATOS PERSONALES"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "ee0b805a1669",
          "_type": "block",
          "children": [
            {
              "_key": "ee0b805a16690",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. puede proporcionar información personal a Socios pertinentes de la Aplicación. El uso del Socio de la Aplicación sobre su información personal está sujeta a la política de privacidad independiente del Socio de la Aplicación y no de esta Declaración de Privacidad. La política de privacidad del Socio de la Aplicación está vinculada a Facebook desde la aplicación del socio o voluntario por parte del usuario."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "68cd6ea28371",
          "_type": "block",
          "children": [
            {
              "_key": "68cd6ea283710",
              "_type": "span",
              "marks": [],
              "text": "¿Fitplan Technologies Inc. COMPARTIRÁ CUALQUIER INFORMACIÓN PERSONAL QUE RECIBE?"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "b073248d7c2c",
          "_type": "block",
          "children": [
            {
              "_key": "b073248d7c2c0",
              "_type": "span",
              "marks": [],
              "text": "La información personal sobre nuestros usuarios es una parte integral de nuestro negocio. Nosotros ni vendemos ni compartimos su información personal a cualquier persona (con la excepción de compartir su información con un Socio de la Aplicación pertinente, consulte la sección anterior \"Acuerdo de los Socios de la Aplicación\"). Compartimos su información personal sólo como se describe a continuación."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "58a07ea28750",
          "_type": "block",
          "children": [
            {
              "_key": "58a07ea287500",
              "_type": "span",
              "marks": [],
              "text": "Socios de la Aplicación: Compartiremos su información personal con un Socio de la Aplicación pertinente (véase la sección anterior \"Acuerdo de Socio de la Aplicación\")."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "10e9862fdcb8",
          "_type": "block",
          "children": [
            {
              "_key": "10e9862fdcb80",
              "_type": "span",
              "marks": [],
              "text": "Agentes: Empleamos a otras compañías y personas para realizar tareas en nuestro nombre y necesitamos compartir su información con ellos para proporcionar productos o servicios para usted. A menos que le indiquemos lo contrario, los agentes de Fitplan Technologies Inc. no tienen ningún derecho a utilizar la información personal que compartimos con ellos más allá de lo necesario para que nos ayude. Usted, por la presente, se somete a compartir información personal para los fines antes mencionados. Transferencias de Negocios: En algunos casos, podemos optar por comprar o vender activos. En este tipo de transacciones, la información del cliente es típicamente uno de los activos comerciales que se transfieren. Por otra parte, si Fitplan Technologies Inc., o sustancialmente todos sus activos fueran adquiridos, o en el caso improbable de que Fitplan Technologies Inc. salga del mercado o entre en quiebra, la información del usuario sería uno de los activos que se transfieren o es adquirida por un tercero. Usted reconoce que dicha transferencia puede ocurrir, y que cualquier adquiridor de Fitplan Technologies Inc. puede seguir utilizando su información personal como se establece en este acuerdo."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "bf978016daa8",
          "_type": "block",
          "children": [
            {
              "_key": "bf978016daa80",
              "_type": "span",
              "marks": [],
              "text": "Protección de Fitplan Technologies Inc. y Otros: Podemos revelar información personal cuando creemos de buena fe que la declaración es necesaria para cumplir con la ley; hacer cumplir o aplicar nuestras condiciones de uso y otros acuerdos; o proteger los derechos, propiedad o seguridad de Fitplan Technologies Inc., nuestros empleados, nuestros usuarios u otros. Esto incluye intercambiar información con otras compañías y organizaciones para la protección contra el fraude y la reducción del riesgo de crédito."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "579d30c278c7",
          "_type": "block",
          "children": [
            {
              "_key": "579d30c278c70",
              "_type": "span",
              "marks": [],
              "text": "Con su consentimiento: A excepción de lo establecido anteriormente, se le notificará cuando su información personal pueda ser compartida con terceros, y podrá prevenir la difusión de esta información."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "579d30c278c71",
          "_type": "block",
          "children": [
            {
              "_key": "579d30c278c701",
              "_type": "span",
              "marks": [],
              "text": "¿CÓMO PUEDES REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE RECOPILAMOS DE TI?"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "579d30c278c72",
          "_type": "block",
          "children": [
            {
              "_key": "579d30c278c702",
              "_type": "span",
              "marks": [],
              "text": "Según las leyes aplicables de tu país, puedes tener el derecho a solicitar acceso a la información personal que recopilamos de ti, cambiar esa información o eliminarla en algunas circunstancias. Para solicitar la revisión, actualización o eliminación tu información personal, envíanos una solicitud a [support@fitplanapp.com]."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "68816b3cce48",
          "_type": "block",
          "children": [
            {
              "_key": "68816b3cce480",
              "_type": "span",
              "marks": [],
              "text": "CONDICIONES DE USO"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "f1ed536d4d6e",
          "_type": "block",
          "children": [
            {
              "_key": "f1ed536d4d6e0",
              "_type": "span",
              "marks": [],
              "text": "Si decide utilizar o acceder a la aplicación, el uso / acceso y cualquier posible disputa sobre privacidad está sujeta a esta Declaración de Privacidad y a las Condiciones de Uso, incluyendo limitaciones en daños, el arbitraje de disputas y la aplicación de la ley del Estado de California."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "2791d18022cc",
          "_type": "block",
          "children": [
            {
              "_key": "2791d18022cc0",
              "_type": "span",
              "marks": [],
              "text": "LAS APLICACIONES / SITIOS WEB DE TERCEROS"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "59a87d7eae0f",
          "_type": "block",
          "children": [
            {
              "_key": "59a87d7eae0f0",
              "_type": "span",
              "marks": [],
              "text": "La Aplicación puede permitirle conectarse con otras aplicaciones o sitios Web. Tales aplicaciones / sitios web de terceros no están bajo el control Fitplan Technologies Inc., y tales enlaces no constituyen una garantía por Fitplan Technologies Inc. de esas otras aplicaciones / sitios web o los servicios que se ofrecen a través de ellos. Las prácticas de privacidad y seguridad de este tipo de aplicaciones / sitios web de terceros vinculados a la Aplicación no están cubiertos por esta Declaración de Privacidad y Fitplan Technologies Inc. no es responsable de las prácticas de privacidad o seguridad o el contenido de dichos sitios web."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "7bfb5929afae",
          "_type": "block",
          "children": [
            {
              "_key": "7bfb5929afae0",
              "_type": "span",
              "marks": [],
              "text": "¿A QUÉ INFORMACIÓN PERSONAL PUEDO ACCEDER?"
            }
          ],
          "markDefs": [],
          "style": "h2"
        },
        {
          "_key": "48f5519c0b4c",
          "_type": "block",
          "children": [
            {
              "_key": "48f5519c0b4c0",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. le permite acceder a la siguiente información sobre usted con el fin de ver, y en ciertas situaciones, actualizar dicha información. Esta lista puede cambiar en caso que la Aplicación cambie."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "25441fef3cd4",
          "_type": "block",
          "children": [
            {
              "_key": "25441fef3cd40",
              "_type": "span",
              "marks": [],
              "text": "Información de cuenta y perfil de usuario"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "61532886b57b",
          "_type": "block",
          "children": [
            {
              "_key": "61532886b57b0",
              "_type": "span",
              "marks": [],
              "text": "Dirección de correo electrónico de usuario, si aplica"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        }, 
        {
          "_key": "c476c866f66a",
          "_type": "block",
          "children": [
            {
              "_key": "c476c866f66a0",
              "_type": "span",
              "marks": [],
              "text": "Información de perfil de Facebook, si aplica"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "751d53894404",
          "_type": "block",
          "children": [
            {
              "_key": "751d538944040",
              "_type": "span",
              "marks": [],
              "text": "Preferencias de usuario"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "c3783e823905",
          "_type": "block",
          "children": [
            {
              "_key": "c3783e8239050",
              "_type": "span",
              "marks": [],
              "text": "Datos específicos de la aplicación"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "5de181cb7d5a",
          "_type": "block",
          "children": [
            {
              "_key": "5de181cb7d5a0",
              "_type": "span",
              "marks": [],
              "text": "HEALTHKIT"
            }
          ],
          "markDefs": [],
          "style": "h2"
        },
        {
          "_key": "9f41cad01720",
          "_type": "block",
          "children": [
            {
              "_key": "9f41cad017200",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. tiene la integración Healthkit de Apple para realizar un seguimiento de los siguientes datos PHR (Personal Health Record - Registro de Salud Personal); duración, pasos realizados, calorías, distancia y el tipo de ejercicio. Los datos PHR se basan en la información de la Aplicación Personal Health Record. Estos datos se pasan a través de Healthkit con el fin de proporcionar retroalimentación en tiempo real métrico al usuario acerca de sus ejercicios, bienestar y programa de entrenamiento.Los datos que recogemos Healthkit se clasifican como Personal Identifiable Information - Información Personal Identificable (PII) y Non Personal Identifiable Information - Información Personal No Identificable (NPII) Para fines de aclaración:"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "dccb5f345c47",
          "_type": "block",
          "children": [
            {
              "_key": "dccb5f345c470",
              "_type": "span",
              "marks": [],
              "text": "PII es cualquier PHR que se conecta a usted como un individuo, tales como nombres, condiciones de salud y otros identificadores. Cualquier dato adicional PHR que es PII pasará a ser parte de esta categoría"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "22cc0dc5dc8f",
          "_type": "block",
          "children": [
            {
              "_key": "22cc0dc5dc8f0",
              "_type": "span",
              "marks": [],
              "text": "NPII es dato PHR que es agrupado 1) para que no se conecte a usted como un individuo y 2) tiene nombres y otros identificadores suprimidos o alterados."
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "d0c0106faa47",
          "_type": "block",
          "children": [
            {
              "_key": "d0c0106faa470",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. NO revelará Healthkit PII a cualquier persona, empresa u otra entidad, por ningún motivo, incluyendo, pero no limitado a:"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "470cbfbfaa66",
          "_type": "block",
          "children": [
            {
              "_key": "470cbfbfaa660",
              "_type": "span",
              "marks": [],
              "text": "Marketing y Publicidad"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "af7ff5796b40",
          "_type": "block",
          "children": [
            {
              "_key": "af7ff5796b400",
              "_type": "span",
              "marks": [],
              "text": "Investigación médica y farmacéutica"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "627881f315bf",
          "_type": "block",
          "children": [
            {
              "_key": "627881f315bf0",
              "_type": "span",
              "marks": [],
              "text": "Investigación médica y farmacéutica"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "6a90bb936877",
          "_type": "block",
          "children": [
            {
              "_key": "6a90bb9368770",
              "_type": "span",
              "marks": [],
              "text": "Su aseguradora y el empleador"
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "b52cf2cd4e1c",
          "_type": "block",
          "children": [
            {
              "_key": "b52cf2cd4e1c0",
              "_type": "span",
              "marks": [],
              "text": "Podemos revelar su información personal a terceros en caso de que sea requerido para ello por la ley o citación o si creemos que dicha acción es necesaria para (a) cumplir con la ley, cumplimiento de un proceso legal que se nos requiera o a nuestros afiliados, o investigar, prevenir o tomar acción respecto a actividades ilegales sospechosas o reales; (b) para hacer cumplir nuestros Términos de Uso, tomar precauciones en cuanto a responsabilidad, para investigar y defendernos contra cualquier reclamación de terceros o alegaciones, para ayudar a las agencias gubernamentales, o para proteger la seguridad o integridad de nuestro sitio; y (c) para ejercer o proteger los derechos, propiedad o seguridad personal de la empresa, o actuar en los mejores intereses de nuestros usuarios u otros."
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "8e42da541f8b",
          "_type": "block",
          "children": [
            {
              "_key": "8e42da541f8b0",
              "_type": "span",
              "marks": [],
              "text": "Podemos compartir información personal no identificable (por ejemplo datos anónimos de uso, páginas de referencia / salida y las direcciones URL, tipos de plataforma, número de clics, número de usuarios usando el health kit, etc.) con proveedores de análisis y otras terceras partes para ayudarles y ayudarnos a comprender los patrones de uso de determinados Servicios. Esta Política de Privacidad de ninguna manera restringe o limita nuestra recopilación y uso de información personal no identificable."
            }
          ],
          "level": 1,
          "listItem": "bullet",
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "251ef3dcf468",
          "_type": "block",
          "children": [
            {
              "_key": "251ef3dcf4680",
              "_type": "span",
              "marks": [],
              "text": "Sus datos personales sólo se recopilan por nosotros a través de su uso directo con la Aplicación Fitplan, y si usted le concedió permiso tanto a nuestra Aplicación como a HealthKit de Apple. Si cierra o transfiere su perfil con nosotros, ya no recopilamos ningún dato de PHR."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "2b901f97def4",
          "_type": "block",
          "children": [
            {
              "_key": "2b901f97def40",
              "_type": "span",
              "marks": [],
              "text": "¿LOS NIÑOS PUEDEN USAR LA APLICACIÓN?"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "c63ae06a9846",
          "_type": "block",
          "children": [
            {
              "_key": "c63ae06a98460",
              "_type": "span",
              "marks": [],
              "text": "\"Nuestro sitio y los servicios disponibles a través de Fitplan Technologies Inc. no están pensados para niños menores de 13 años. Fitplan Technologies Inc. no recopila de manera específica ni deliberada información sobre niños menores de 13 años y cree que los niños de cualquier edad deben obtener el consentimiento de sus padres antes de suministrar cualquier tipo de información personal. Lo alentamos a participar en nuestra experiencia web para niños."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "7459bc5d87e4",
          "_type": "block",
          "children": [
            {
              "_key": "7459bc5d87e40",
              "_type": "span",
              "marks": [],
              "text": "CAMBIOS A ESTA DECLARACIÓN DE PRIVACIDAD"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "252d192fe71b",
          "_type": "block",
          "children": [
            {
              "_key": "252d192fe71b0",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. puede modificar esta Declaración de Privacidad eventualmente. El uso de la información que recopilamos ahora está sujeto a la Declaración de Privacidad en efecto en el momento que tal información sea utilizada. Si realizamos cambios en la forma que utilizamos la información personal, le notificaremos publicando un anuncio en nuestro Sitio o enviándole un correo electrónico. Los usuarios deben acatar cualquier cambio a la Declaración de Privacidad cuando él o ella use o de otro modo acceda a la Aplicación después de que tales cambios hayan sido publicados por primera vez."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "77a77adb50aa",
          "_type": "block",
          "children": [
            {
              "_key": "77a77adb50aa0",
              "_type": "span",
              "marks": [],
              "text": "PREGUNTAS O INQUIETUDES"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "8212081976a4",
          "_type": "block",
          "children": [
            {
              "_key": "8212081976a40",
              "_type": "span",
              "marks": [],
              "text": "Si usted tiene alguna pregunta o inquietud con respecto a la privacidad en nuestro Sitio web, por favor envíenos un mensaje detallado a Fitplan Technologies Inc. Haremos todo lo posible por resolver sus inquietudes."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "545ba5079fcf",
          "_type": "block",
          "children": [
            {
              "_key": "545ba5079fcf0",
              "_type": "span",
              "marks": [],
              "text": "Fecha efectiva: 1 de enero de 2016"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "77866b273273",
          "_type": "block",
          "children": [
            {
              "_key": "77866b2732730",
              "_type": "span",
              "marks": [],
              "text": "Aviso Legal"
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "8d7aea636ae2",
          "_type": "block",
          "children": [
            {
              "_key": "8d7aea636ae20",
              "_type": "span",
              "marks": [],
              "text": "Por favor revise atentamente el siguiente Acuerdo de Usuario antes de usar la aplicación de Fitplan o cualquier propiedad intelectual perteneciente a Fitplan Technologies Inc. Usted también debe leer nuestra Política de Privacidad."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "7424d9321d22",
          "_type": "block",
          "children": [
            {
              "_key": "7424d9321d220",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. recomienda firmemente que consulte con su médico antes de empezar cualquier programa de ejercicios."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "2fcbbfa1352f",
          "_type": "block",
          "children": [
            {
              "_key": "2fcbbfa1352f0",
              "_type": "span",
              "marks": [],
              "text": "Debe encontrarse en buena condición física y ser capaz de participar en el ejercicio."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "e730b7d92514",
          "_type": "block",
          "children": [
            {
              "_key": "e730b7d925140",
              "_type": "span",
              "marks": [],
              "text": "Fitplan Technologies Inc. y sus entrenadores no son un proveedor de atención médica autorizado y representa que no posee experiencia en el diagnóstico, examinación, o tratamiento de condiciones médicas de ningún tipo, o en determinar el efecto de cualquier ejercicio específico en una condición médica."
            }
          ],
          "markDefs": [],
          "style": "normal"
        },
        {
          "_key": "2181e44372a0",
          "_type": "block",
          "children": [
            {
              "_key": "2181e44372a00",
              "_type": "span",
              "marks": [],
              "text": "Debe comprender que cuando participa en cualquier ejercicio o programa de ejercicio, existe la posibilidad de lesiones físicas. Si participa en este ejercicio o programa de ejercicios, está de acuerdo en que lo realiza bajo su propio riesgo, está participando de manera voluntaria en estas actividades, asume todos los riegos de hacerse daño usted mismo, y está de acuerdo en exonerar y exculpar a Fitplan Technologies Inc. y sus entrenadores de todas las reclamaciones o causas de acción, conocidas o desconocidas, derivadas de la negligencia de Fitplan Technologies Inc."
            }
          ],
          "markDefs": [],
          "style": "normal"
        }
      ]
    }
  }

  return (
    <PageWrapper {...props} className="policy__layout">
      <div className="policy__container">
        <h1 style={{ textAlign: 'center' }}>{sanityLegal.title}</h1>

        <section
          style={{
            margin: '0 auto',
            maxWidth: '960px',
            padding: '16px 16px 72px',
          }}
        >
          <BlockContent blocks={sanityLegal._rawTerms} />
        </section>
      </div>
    </PageWrapper>
  );
};

/*
export const query = graphql`
  query privacyPolicyQuery {
    sanityLegal(slug: { eq: "privacy-policy" }) {
      title {
        _type
        en
        es
      }
      _rawTerms
    }
  }
`;
*/

export default localize(PrivacyPolicyPage);
